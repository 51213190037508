<template>
	<div>
		<div class="table-responsive">
			<table class="table b-table table-fixed table-bordered">
				<thead>
					<tr>
						<th>
						<div class="d-flex" style="gap: 10px;">
							<b-img
								class="filter-image"
								:class="filter.order_type == 'asc' ? 'rotate-180' : ''"
								:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
								alt="Icon-order-list-down"
								role="button"
								@click="filter.order_type == 'asc' ? filter.order_type = 'desc' : filter.order_type = 'asc'"
							/>
							<span>No</span>
						</div>
						</th>
						<th>Nama Vendor</th>
						<th>Owner</th>
						<th>No. Hp</th>
						<th>Alamat Vendor</th>
						<th>Categori Vendor</th>
						<th>Provinsi</th>
						<th>Kota</th>
						<th>Status Dokumen</th>
						<th>Status</th>
						<th>Status Verified</th>
						<th>Rekomendasi</th>
						<!-- <th>Social Media</th> -->
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="isLoadingComponent">
						<td
							colspan="12"
							class="text-center"
						>
						<b-spinner
							class="mb-1"
							variant="primary"
						/><br>
							Loading...
						</td>
					</tr>
					<tr
						v-for="item, index in vendorList" :key="item.id"
					>
						<td>
							{{ (currentPageList - 1) * 10 + index + 1 }}
						</td>
						<td>
							<div>
									<div class="text-detail" v-html="item.vendor_name || '-'"></div>
									<span  class="badge badge-primary" v-if="item.is_flag">
										<feather-icon
										icon="TagIcon"
									/>
									Pinned</span>
								</div>
						</td>
						<td>
							{{ (item.owner) ? (item.owner.user_name) ? item.owner.user_name : '-' : '-' }}
						</td>
						<td>
							{{ (item.owner) ? (item.owner.user_phone) ? item.owner.user_phone : '-' : '-' }}
						</td>
						<td>
							{{ item.address || '-' }}
						</td>
						<td>
							{{ (item.category) ? item.category.vendor_category_name : '-'}}
						</td>
						<td>
                            {{ (item.province) || '-' }}
						</td>
						<td>
                            {{ (item.city) || '-' }}
						</td>
                        <td>
                            {{ item.progress_file || '-' }}
                        </td>
                        <td>
                            {{ item.status || '-' }}
                        </td>
						<td>
                            {{ item.is_verified === 1 ? 'Verified' : 'Unverified' }}
                        </td>
						<td>
							<div class="custom-control custom-switch text-center" @click="updateRecommendation(item.id, true)" v-if="!item.is_recommendation">
								<input type="checkbox" class="custom-control-input" :id="'customSwitch' + item.id" :checked="item.is_recommendation">
								<label class="custom-control-label" :for="'customSwitch' + item.id"></label>
							</div>

							<div class="custom-control custom-switch text-center" @click="updateRecommendation(item.id, false)" v-if="item.is_recommendation">
								<input type="checkbox" class="custom-control-input" :id="'customSwitch' + item.id" :checked="item.is_recommendation">
								<label class="custom-control-label" :for="'customSwitch' + item.id"></label>
							</div>
						</td>
                        <!-- <td>
                            {{  '-' }}
                        </td> -->
						<td class="text-right">
							<b-dropdown
								variant="danger"
								size="sm"
							>
								<template #button-content>
									Action
								</template>
								
								<b-dropdown-item>
									<router-link :to="'/detail-vendor/' + item.id">
										<feather-icon
											icon="EditIcon"
											class="mr-75"
										/>
										Update
									</router-link>
								</b-dropdown-item>

								<b-dropdown-item @click="updateFlag(item.id, true)" v-if="!item.is_flag">
									<feather-icon
											icon="TagIcon"
											class="mr-75"
										/> Pin Vendor
								</b-dropdown-item>

								<b-dropdown-item @click="updateFlag(item.id, false)" v-if="item.is_flag">
									<feather-icon
											icon="TagIcon"
											class="mr-75"
										/> Unpin Vendor
								</b-dropdown-item>

								<b-dropdown-item @click="updateVerified(item.id, true)" v-if="!item.is_verified">
									<feather-icon
											icon="TagIcon"
											class="mr-75"
										/> Verified Vendor
								</b-dropdown-item>

								<b-dropdown-item @click="updateVerified(item.id, false)" v-if="item.is_verified">
									<feather-icon
											icon="TagIcon"
											class="mr-75"
										/> Unverified Vendor
								</b-dropdown-item>

								<b-dropdown-item
									@click="deleteItem(item.id)"
								>
								<feather-icon
									icon="TrashIcon"
									class="mr-75"
								/>
									Delete
								</b-dropdown-item>
							</b-dropdown>
						</td>
					</tr>
					<tr v-if="resultData.total == 0 && !isLoadingComponent">
						<td
							colspan="12"
							class="text-center"
						>
							Data is empty.
						</td>
					</tr>
				</tbody>
			</table>

			<div
				v-if="resultData.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="resultData"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { 
	BTable,
	BSpinner,
	BBadge,
	BImg,
	BDropdown,
  	BDropdownItem,
} from 'bootstrap-vue'
import _ from 'lodash'
export default {
	components: {
		BTable,
		BSpinner,
		BBadge,
		BImg,
		BDropdown,
		BDropdownItem,
	},
	props: {
		vendor: {
			type: '',
		},
		result: {
			type: '',
		},
		isLoading: {
			type: Boolean,
		},
		currentPage: {
			type: Number,
		},
		getData: {
			type: Function,
		},
		deleteItem: {
			type: Function,
		},
		updateFlag: {
			type: Function,
		},
		updateVerified: {
			type: Function
		},
		updateRecommendation: {
			type: Function,
		}
	},
	watch: {
		isLoading(value) {
			this.isLoadingComponent = value
		},
		vendor(value) {
			this.vendorList = value
		},
		currentPage(value) {
			this.currentPageList = value
		},
		result(value) {
			this.resultData = value
		},
		filter: {
			handler(value) {
				this.$emit('filter', value)
			},
			deep: true
		}
	},
	data() {
		return {
			isLoadingComponent: true,
			currentPageList: 1,
			resultData:{},
			vendorList: [],
			filter: {
				order_type: 'desc'
			},
			path: process.env.URL_LANDING_PAGE
		}
	},
	computed: {
		rows() {
			return this.vendorList.length
		}
	},
}
</script>

<style>
.text-detail {
	width: 350px;
}
.table tbody tr:not([class*=table-]) td {
	vertical-align: top;
}
</style>